import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider, createConfig } from 'wagmi'
import { mainnet, arbitrum, sapphire } from '@wagmi/core/chains'
import { coinbaseWallet, injected, safe, walletConnect } from 'wagmi/connectors'
import { http } from 'viem'
import { createWeb3Modal } from '@web3modal/wagmi/react'

const queryClient = new QueryClient()
const PROJECT_ID = '9cefee5d4f1939320b37915975484bb9';
const metadata = {
    name: 'gloop',
    description: 'Increase your capital efficiency',
    url: 'https://gloop.com',
    icons: []
}

export default function Wrapper({children}) {
    const projectId = PROJECT_ID;
    const chains = [arbitrum];

    const wc = walletConnect({ projectId, metadata, showQrModal: false });
    const inject = injected({ shimDisconnect: true });
    const coinbase = coinbaseWallet({ appName: metadata.name });
    const connectors = [wc, inject, coinbase, safe()];

    const config = createConfig({
        chains,
        connectors,
        projectId,
        transports: {
            [arbitrum.id]: http("https://arb-mainnet.g.alchemy.com/v2/zEy-TYdQtXLogxVBLHFbVKlIXLmmONEg"),
        },
    });
    createWeb3Modal({ wagmiConfig: config, projectId, chains })

    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WagmiProvider>
    )
}
