import { useContext, useEffect, useState } from 'react';
import Info from '../Info';
import { erc20Abi, hexToString } from 'viem';
import { Collapse, Table } from 'react-bootstrap';
import { useAccount, useReadContract } from "wagmi";
import { GM_INDEX_ADDRESS, tokens } from './constants';
import { truncateEther } from '../../utils/number';
import GMIndexABI from "../../abis/GMIndex.json"

import gmi_img3_url from '../../assets/img/gmi_img3.svg';
import gmi_img4_url from '../../assets/img/gmi_img4.svg';
import gmi_img5_url from '../../assets/img/gmi_img5.svg';
import gmi_img7_url from '../../assets/img/gmi_img7.svg';
import { GMIContext } from '../../Context';
import { useIndexStats } from '../../hooks';

export default function FromGmi({ amount, setAmount, showUSDC = true }) {
    const { depositToken, setDepositToken } = useContext(GMIContext);
    useEffect(() => {
        if (!showUSDC && depositToken == "USDC") {
            setDepositToken("ethGM");
        }
    })
    const [openDropDown, setOpenDropDown] = useState(false);
    const { address } = useAccount();

    const token = tokens[depositToken];

    const ethStats = useIndexStats(tokens.ethGM.key);
    const btcStats = useIndexStats(tokens.btcGM.key);
    const solStats = useIndexStats(tokens.solGM.key);
    const stableStats = useIndexStats(tokens.stableGM.key);

    const stats = {
        btcGM: btcStats,
        ethGM: ethStats,
        solGM: solStats,
        stableGM: stableStats,
    }

    const lowestDepositFee = [ethStats, btcStats, solStats, stableStats].reduce(function(prev, curr) {
        return prev.depositFee < curr.depositFee ? prev : curr;
    });
    const lowestWithdrawFee = [ethStats, btcStats, solStats, stableStats].reduce(function(prev, curr) {
        return prev.withdrawFee < curr.withdrawFee ? prev : curr;
    });
    const lowestDepositFeeToken = stats[Object.keys(tokens).filter(token => tokens[token].key == lowestDepositFee.key)[0]];
    const lowestWithdrawFeeToken = stats[Object.keys(tokens).filter(token => tokens[token].key == lowestWithdrawFee.key)[0]];

    const { data: tokenBalance } = useReadContract({
        abi: erc20Abi,
        address: token?.address,
        chainId: 42161,
        functionName: "balanceOf",
        args: [address],
    })

    const { data: price } = useReadContract({
        abi: GMIndexABI.abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "getPrice",
        args: [
            token.key,
            false,
        ],
    })

    const gmiDropDownHandler = () =>{
        if(openDropDown) {
            setOpenDropDown(false);
        }
    }

    window.addEventListener('click', (e) => {
        if(e.target !== document.querySelector('.gmi_dropdown_btn') && e.target !== document.querySelector('.gmi_dropdown_btn1')){
            gmiDropDownHandler();
        }
    })

    const amountHandler = (e) => {
        if (e.target.value == '') {
            return setAmount("");
        }
        if (e.target.value.match("^[0-9]+(\.[0-9]*)?$")) {
            setAmount(e.target.value);
        }
    }

    return (
        <div className="radius-8 bg-trans p-3 position-relative">
            <div className='d-flex v-center space-between'>
                <div>
                    <div className='font-20 bold-700 color-white'>
                        <input type="text" placeholder='0.00' className='buy_gmi_inputs' value={amount} onChange={(e) => amountHandler(e)} />
                    </div>
                    <div className='font-14 bold-300 color-gray mt-2'>${token.name == "USDC" ? Number(amount).toFixed(2) : (truncateEther(price) * amount).toFixed(2)}</div>
                </div>
                <div className='text-end'>
                    <div className='d-flex v-center1 flex-end cursur-pointer'>
                        <img src={token?.img} width={22} className='mr-10 gmi_dropdown_btn' onClick={() => setOpenDropDown(!openDropDown)}/>
                        <div className='font-20 bold-500 color-white gmi_dropdown_btn1' onClick={() => setOpenDropDown(!openDropDown)}>{token?.name} {openDropDown ? "▴" : "▾"}</div>
                    </div>
                    <div className='font-14 bold-300 color-gray mt-2'>Balance: [{token.decimals == 18 ? truncateEther(tokenBalance || 0n) : ((tokenBalance || 0n) / 10000n).toString() / 100}]</div>
                </div>
            </div>
            <div>
                <Collapse in={openDropDown} className='gmi_dropdown'>
                    <div>
                        <div className="p-3 bg-gray radius-8 gmi_dropdown_div">
                            <Table borderless className='gmi_dropdown_table'>
                                <thead >
                                    <tr>
                                        <th className='color-gray font-16 bold-300 text-start py-3'>Asset</th>
                                        <th className='color-gray font-16 bold-300 text-center py-3'>Deposit Fee</th>
                                        <th className='color-gray font-16 bold-300 text-end py-3'>Withdrawal Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {showUSDC && <tr className='cursur-pointer' onClick={() => setDepositToken("USDC")}>
                                        <td className='border-left-radius-8 py-3'>
                                            <img src={gmi_img3_url} width={22} className='mr-10'/><span className='color-white font-16 bold-700 '>USDC</span>
                                        </td>
                                        <td className='color-white font-16 bold-400 text-center py-3'>{lowestDepositFeeToken.depositFee.toString() / 100}%</td>
                                        <td className='border-right-radius-8 color-white font-16 bold-400 text-end py-3'>{lowestWithdrawFeeToken.withdrawFee.toString() / 100}%</td>
                                    </tr>}

                                    <tr className='cursur-pointer' onClick={() => setDepositToken("ethGM")}>
                                        <td className='border-left-radius-8 py-3'>
                                            <img src={gmi_img4_url} width={22} className='mr-10'/><span className='color-white font-16 bold-700 '>ETH GM</span>
                                        </td>
                                        <td className='color-white font-16 bold-400 text-center py-3'>{(ethStats.depositFee.toString() / 100).toString()}%</td>
                                        <td className='border-right-radius-8 color-white font-16 bold-400 text-end py-3'>{(ethStats.withdrawFee.toString() / 100).toString()}%</td>
                                    </tr>
                                    <tr className='cursur-pointer' onClick={() => setDepositToken("btcGM")}>
                                        <td className='border-left-radius-8 py-3'>
                                            <img src={gmi_img5_url} width={22} className='mr-10'/><span className='color-white font-16 bold-700 '>BTC GM</span>
                                        </td>
                                        <td className='color-white font-16 bold-400 text-center p-3'>{(btcStats.depositFee.toString() / 100)}%</td>
                                        <td className='border-right-radius-8 color-white font-16 bold-400 text-end py-3'>{(btcStats.withdrawFee.toString() / 100).toString()}%</td>
                                    </tr>
                                    <tr className='cursur-pointer' onClick={() => setDepositToken("solGM")}>
                                        <td className='border-left-radius-8 py-3'>
                                            <img src={gmi_img7_url} width={22} className='mr-10'/><span className='color-white font-16 bold-700 '>SOL GM</span>
                                        </td>
                                        <td className='color-white font-16 bold-400 text-center p-3'>{(solStats.depositFee.toString() / 100).toString()}%</td>
                                        <td className='border-right-radius-8 color-white font-16 bold-400 text-end py-3'>{(solStats.withdrawFee.toString() / 100).toString()}%</td>
                                    </tr>
                                    <tr className='cursur-pointer' onClick={() => setDepositToken("stableGM")}>
                                        <td className='border-left-radius-8 py-3'>
                                            <img src={gmi_img3_url} width={22} className='mr-10'/><span className='color-white font-16 bold-700 '>USDC-USDT GM</span>
                                        </td>
                                        <td className='color-white font-16 bold-400 text-center p-3'>{(stableStats.depositFee.toString() / 100).toString()}%</td>
                                        <td className='border-right-radius-8 color-white font-16 bold-400 text-end py-3'>{(stableStats.withdrawFee.toString() / 100).toString()}%</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
