import { useSimulateContract, useWriteContract } from 'wagmi';
import { encodeFunctionData, toBytes } from 'viem';
import ExchangeRouter from "../abis/gmx/ExchangeRouter.json";
import { GMX_DEPOSIT_VAULT_ADDRESS, GMX_EXCHANGE_ROUTER_ADDRESS, MARKETS, ZAP_ADDRESS } from '../components/gmi/constants';
import { ZeroAddress } from 'ethers';


export default function useDoZap(marketName, amount, executionFee) {
    const market = MARKETS[marketName];
    const { writeContract } = useWriteContract();
    const UI_FEE_RECEIVER_ACCOUNT = "0x46B18d51f271F7E000Aa01316ab23251b502b240"
    const callbackFee = 2000000n;
    const executionFeeMargin = executionFee * 13n / 10n;
    const value = (executionFeeMargin + callbackFee * 120000000n) || 0n;

    let encodedPayload = [
        encodeFunctionData({
            abi: ExchangeRouter.abi,
            functionName: "sendWnt",
            args: [
                GMX_DEPOSIT_VAULT_ADDRESS,
                value,
            ],
        }),
        encodeFunctionData({
            abi: ExchangeRouter.abi,
            functionName: "sendTokens",
            args: [
                marketName == "USDC-USDT GM" ? market.initialLongToken : market.initialShortToken,
                GMX_DEPOSIT_VAULT_ADDRESS,
                amount,
            ],
        }),
        encodeFunctionData({
            abi: ExchangeRouter.abi,
            functionName: "createDeposit",
            args: [
                [
                    ZAP_ADDRESS,
                    ZAP_ADDRESS, // callback
                    UI_FEE_RECEIVER_ACCOUNT,
                    market.market,
                    market.initialLongToken,
                    market.initialShortToken,
                    [],
                    [],
                    1n,
                    false,
                    executionFeeMargin,
                    callbackFee,
                ]
            ],
        //     args: [
        //         {
        //             receiver: ZAP_ADDRESS,
        //             callbackContract: "0x0000000000000000000000000000000000000000",
        //             market: market.market,
        //             initialLongToken: market.initialLongToken,
        //             initialShortToken: market.initialShortToken,
        //             longTokenSwapPath: [],
        //             shortTokenSwapPath: [],
        //             minMarketTokens: 1n,
        //             shouldUnwrapNativeToken: false,
        //             executionFee,
        //             callbackGasLimit: 100000000000n,
        //             uiFeeReceiver: "0x0000000000000000000000000000000000000000",
        //         },                
        //     ],
        }),
    ];

    const { data: zapData, error } = useSimulateContract({
        abi: ExchangeRouter.abi,
        chainId: 42161,
        address: GMX_EXCHANGE_ROUTER_ADDRESS,
        functionName: "multicall",
        args: [encodedPayload],
        value,
        enabled: !!executionFee && amount > 0 && value > 0,
    })

    async function doZap() {
        writeContract(zapData?.request);
    }

    return doZap;
}
