import { useEffect, useState } from 'react';
import { useReadContract } from 'wagmi';
import { erc20Abi } from 'viem';
import { GM_INDEX_ADDRESS } from '../components/gmi/constants';
import GMIndexABI from '../abis/GMIndex.json';

const useGMIPrice = () => {
    const [price, setPrice] = useState("0");
    const { data: totalSupply } = useReadContract({
        abi: erc20Abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "totalSupply",
        args: [],
    })
    const { data: totalControlledValue } = useReadContract({
        abi: GMIndexABI.abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "totalControlledValue",
        args: [true],
    })

    useEffect(() => {
        setPrice(Number((totalControlledValue || 0n) * 100000n / (totalSupply || 1n)));
    }, [totalControlledValue, totalSupply]);

    return Number(price / 100000).toFixed(4);
}

export default useGMIPrice;
