import gmi_img3_url from '../../assets/img/gmi_img3.svg';
import gmi_img4_url from '../../assets/img/gmi_img4.svg';
import gmi_img5_url from '../../assets/img/gmi_img5.svg';
import gmi_img6_url from '../../assets/img/gmi_img6.svg';
import gmi_img7_url from '../../assets/img/gmi_img7.svg';

const USDC = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831";
const USDT = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9";

const WSOL = "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07";
const MARKETS = {
    "SOL GM": {
        market: "0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9",
        initialLongToken: WSOL,
        initialShortToken: USDC,
        ratio: 20,
    },
    "BTC GM": {
        market: "0x47c031236e19d024b42f8AE6780E44A573170703",
        initialLongToken: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
        initialShortToken: USDC,
        ratio: 45,
    },
    "ETH GM": {
        market: "0x70d95587d40A2caf56bd97485aB3Eec10Bee6336",
        initialLongToken: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        initialShortToken: USDC,
        ratio: 30,
    },
    "USDC-USDT GM": {
        market: "0xB686BcB112660343E6d15BDb65297e110C8311c4",
        initialLongToken: USDC,
        initialShortToken: USDT,
        ratio: 5,
    }
    // "ARB GM": {
    //     market: "0xC25cEf6061Cf5dE5eb761b50E4743c1F5D7E5407",
    //     initialLongToken: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    //     initialShortToken: USDC,
    // },
}

const tokens = {
    USDC: {
        name: "USDC",
        address: USDC,
        img: gmi_img3_url,
        key: "",
        ratio: 0,
        decimals: 6,
    },
    btcGM: {
        name: "BTC GM",
        address: "0x47c031236e19d024b42f8AE6780E44A573170703",
        img: gmi_img5_url,
        key: "0x5742544300000000000000000000000000000000000000000000000000000000",
        ratio: 45,
        decimals: 18,
    },
    ethGM: {
        name: "ETH GM",
        address: "0x70d95587d40A2caf56bd97485aB3Eec10Bee6336",
        img: gmi_img4_url,
        key: "0x5745544800000000000000000000000000000000000000000000000000000000",
        ratio: 30,
        decimals: 18,
    },
    solGM: {
        name: "SOL GM",
        address: "0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9",
        img: gmi_img7_url,
        key: "0x534f4c0000000000000000000000000000000000000000000000000000000000",
        ratio: 20,
        decimals: 18,
    },
    stableGM: {
        name: "USDC-USDT GM",
        address: "0xB686BcB112660343E6d15BDb65297e110C8311c4",
        img: gmi_img3_url,
        key: "0x555344432d555344542d53574150000000000000000000000000000000000000",
        ratio: 5,
        decimals: 18,
    },
    // arbGM: {
    //     name: "ARB GM",
    //     address: "0xC25cEf6061Cf5dE5eb761b50E4743c1F5D7E5407",
    //     img: gmi_img6_url,
    //     key: "0x4152420000000000000000000000000000000000000000000000000000000000",
    //     ratio: 10,
    //     decimals: 18,
    // },
}

// const GM_INDEX_ADDRESS = "0xef5f86dC8fD472d9E32fa22BA7cE14a63c396B54";
const GM_INDEX_ADDRESS = "0xAad4187a81689AF72d91966c8119756E425cD7CF";
const GMX_EXCHANGE_ROUTER_ADDRESS = "0x7C68C7866A64FA2160F78EEaE12217FFbf871fa8";
const GMX_DEPOSIT_VAULT_ADDRESS = "0xF89e77e8Dc11691C9e8757e84aaFbCD8A67d7A55";
const GMX_DATA_STORE = "0xFD70de6b91282D8017aA4E741e9Ae325CAb992d8";
// const ZAP_ADDRESS = "0xA4eeDcC62a96Ca825F84f35bC4F8c5643c0D8d9E";
// const ZAP_ADDRESS = "0xfAeC53c34E841eBd0a43588422eA713ceFb7f225";
const ZAP_ADDRESS = "0x79079A231AAC05d96e60656146bB6bfe4280F51f";
const GMX_SYNTHETICS_ROUTER_ADDRESS = "0x7452c558d45f8afc8c83dae62c3f8a5be19c71f6";

const WITHDRAWAL_VAULT_ADDRESS = "0x0628D46b5D145f183AdB6Ef1f2c97eD1C4701C55";

export {
    GM_INDEX_ADDRESS,
    GMX_DATA_STORE,
    GMX_DEPOSIT_VAULT_ADDRESS,
    GMX_EXCHANGE_ROUTER_ADDRESS,
    GMX_SYNTHETICS_ROUTER_ADDRESS,
    MARKETS,
    ZAP_ADDRESS,
    tokens,
}
