import { Container, Navbar, Nav, Button, Collapse, Dropdown } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useDisconnect, useSwitchChain } from 'wagmi';

import logo from "../../assets/img/logo.svg";
import mobile_logo from "../../assets/img/mobile-logo.svg";
import gloop from "../../assets/img/gloop.svg";
import icon1 from "../../assets/img/icon1.svg";
import notFound from "../../assets/img/not-found.svg";
import down_arrow from "../../assets/img/down-arrow.svg";
import active_url from "../../assets/img/active.png";
import etherum_icon from "../../assets/img/from_icon.svg";
import facebook_url from "../../assets/img/facebook.svg";
import discord_url from "../../assets/img/discord.svg";
import book_url from "../../assets/img/book.svg";
import m_url from "../../assets/img/m.svg";
import dropdown_close_url from "../../assets/img/dropdown-close.svg";
import dropdown_open_url from "../../assets/img/dropdown-open.svg";

export default function Header() {
  const DISABLE_BUTTONS = true;

  const { pathname } = useLocation();
  const { disconnect } = useDisconnect();
  const [open, setOpen] = useState(false);
  const [selectedChain, setSelectedChain] = useState(icon1)
  const [chainMenuActive, setChainMenuActive] = useState("")
  const [activeMenu, setActiveMenu] = useState("Enclave");
  const [resourceDropUrl, setResourceDropUrl] = useState(dropdown_close_url);
  const [resourceDropStatus, setResourceDropStatus] = useState(false);
  const { switchChain } = useSwitchChain()

  const { open: openModal } = useWeb3Modal()
  const { address, chain, isConnected } = useAccount();

  useEffect(() => {
    if (chain?.id == 42161) {
      setSelectedChain(icon1);
      setChainMenuActive("arbitrum")
    } else {
      setSelectedChain(notFound);
      setChainMenuActive("")
    }
  }, [chain])


  const chainMenuHandler = () => {
    if(open){
      setOpen(false);
    } else {
      setOpen(true);
    }
  }
  const closeMenuhandler = () => {
    if(open){
      setOpen(false);
    }
  }
  window.addEventListener('click', (e) => {
    // ignore resource dropdown
    if (e.target.id === "resource-dropdown" || e.target.id == "resource-dropdown-arrow") {
      return;
    }
    if(e.target !== document.querySelector('.chain-menu') && e.target !== document.querySelector('.menu-toggle img') && e.target !== document.querySelector('.menu-toggle .img_down_arrow')){
      closeMenuhandler();
    }
    if(!document.querySelector('.navbar-toggler').classList.contains("collapsed") && e.target !== document.querySelector('.navbar-toggler') && e.target !== document.querySelector('.navbar-toggler-icon')){
      closeMobileMenu();
    }
  })

  const arbitrumHandler = () => {
    switchChain({
      chainId: 42161,
    }, {
        onSuccess: () => {
        setSelectedChain(icon1);
        setChainMenuActive("arbitrum");
        }
    });
  }

  const closeMobileMenu = () => {
    var aa = window.innerWidth;
    if (aa < 980) {
      document.querySelector(".navbar-toggler").click();
    }
  }

  const resourceDropDownHandler = (e) =>{
    if (resourceDropStatus) {
        setResourceDropUrl(dropdown_close_url);
        setResourceDropStatus(false);
    } else{
      setResourceDropUrl(dropdown_open_url);
      setResourceDropStatus(true);
    }
  }

  return (
    <header>
      <Navbar expand="lg">
      <Container className='py-3'>
          <Navbar.Brand>
            <Link to="/" >
              <img src={logo}  width="140px" className='desktop-show'/>
              <img src={mobile_logo}  width="54px" className='mobile-show'/>
            </Link>
          </Navbar.Brand>
          {address ? (
            <Button
              className='connect_btn connect_btn_mobile mobile-show'
              onClick={() => disconnect()}
            >
              {address.slice(0, 2)}..{address.slice(-4)}
            </Button>
            ) : (
              <Button
                className='connect_btn connect_btn_mobile mobile-show'
                onClick={() => openModal()}
              >
                Connect
              </Button>
          )}
          <Navbar.Toggle aria-controls="navbarScroll" />

          <Navbar.Collapse id="navbarScroll">
            <Nav className="my-2" >
                <Dropdown className="mobile-show">
                  <Dropdown.Toggle variant="success" id="dropdown-basic" >
                    <span id="resource-dropdown" onClick={resourceDropDownHandler}>Resources</span>
                    <img id="resource-dropdown-arrow" src={resourceDropUrl} width={6} className='mx-2'/>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="https://docs.gloop.finance/gloop-litepaper-v2/introduction" target='_blank'>Documentation</Dropdown.Item>
                    <Dropdown.Item href="https://gloopfinance.medium.com/" target='_blank'>Guide</Dropdown.Item>
                    {/* <Dropdown.Item href="https://github.com/" target='_blank'>Github</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
                <Link to="https://docs.gloop.finance/gloop-litepaper-v2/introduction" target="_blank" className={`menu-item desktop-show font-14 color-white-50 none-text-line px-3 ${activeMenu == "Docs" ? "active_menu" : ""}`} onClick={(e) => {setActiveMenu("Docs"); closeMobileMenu();}} >Docs</Link>
                <Link
                  to="/gmi"
                  className={`menu-item font-14 color-white-50 none-text-line px-3 ${pathname.toLowerCase() == "/gmi" ? "active_menu" : ""}`} onClick={(e) => {setActiveMenu("GMI"); closeMobileMenu();}} >
                    GMI
                </Link>
                <span style={{ 'cursor': 'default'}} className={`menu-item font-14 color-white-50 none-text-line px-3  ${activeMenu == "Borrow" ? "active_menu" : ""}`} onClick={(e) => {closeMobileMenu();}}>Borrow (Soon)</span>
                <span style={{ 'cursor': 'default'}} className="menu-item font-14 color-white-50 none-text-line px-3">Gloop Presale (Soon)</span>
                {/* <Link to="/gloop-presale" className={`menu-item font-14 color-white-50 none-text-line px-3  ${activeMenu == "Gloop" ? "active_menu" : ""}`} onClick={(e) => {setActiveMenu("Gloop"); closeMobileMenu();}} >Gloop Presale</Link> */}
                {/* <Link to="/mint" className={`menu-item font-14 color-white-50 none-text-line px-3  ${activeMenu == "Mint" ? "active_menu" : ""}`} onClick={(e) => {setActiveMenu("Mint"); closeMobileMenu();}} >Mint</Link> */}
                <div className="menu-item mobile-show  px-3 mt-2">
                  <a href="https://twitter.com/gloopfinance" target="_blank" className='social-item'><img src={facebook_url} width={18} /></a>
                  <a href="https://discord.com/invite/Q22GkjWpcM" target="_blank" className='social-item'><img src={discord_url} width={18} /></a>
                  <a href="https://docs.gloop.finance/gloop-litepaper-v2/introduction" target="_blank" className='social-item'><img src={book_url} width={18} /></a>
                  <a href="https://gloopfinance.medium.com/" target="_blank" className='social-item'><img src={m_url} width={18} /></a>
                </div>
            </Nav> 
          </Navbar.Collapse>
          
          <Nav className='position-relative chain_icon_menu desktop-show'>
              <div className='d-flex align-items-center' >
                <div className='cursur-pointer menu-toggle' onClick={chainMenuHandler}>
                    <img src={selectedChain} width={38} className='px-1'/>
                    <img src={down_arrow} className='img_down_arrow' width={6}/>
                </div>
                <Collapse in={open} className=''>
                  <div className='chain-menu'>
                      <div className='font-12 bold-700 color-dark-blue px-2'>Switch Networks</div>
                      <div className='d-flex space-between align-items-center p-2 cursur-pointer chain-menu-item' onClick={arbitrumHandler}>
                        <div className=''>
                          <img src={icon1} width={25}/> <span className='font-14 bold-700 color-dark-blue'>ARBITRUM</span>
                        </div>
                        <div>
                          {
                            chainMenuActive === "arbitrum" &&
                              (<img src={active_url} />)
                          }
                        </div>
                      </div>
                  </div>
                </Collapse>
                {address ? (
                    <Button
                        className='connect_btn px-4'
                        style={{ fontSize: '8px' }}
                        onClick={() => disconnect()}
                    >{address.slice(0, 2)}..{address.slice(-4)}</Button>
                    ) : (
                    <Button
                        className='connect_btn px-4'
                        onClick={() => openModal()}
                    >
                        Connect
                    </Button>
                )}
              </div>
          </Nav>
      </Container>
    </Navbar>
    </header>    
  )
}
