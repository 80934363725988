import { formatEther } from 'viem'

const truncateEther = (b, d=2, unit='wei') => {
    const es = formatEther(b || 0n, unit)
    const est = es.split('.')
    if (est.length < 2) {
      return es
    }
    return est[0] + '.' + est[1].substring(0, d)
}

export {
    truncateEther,
}
