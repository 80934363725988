import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from 'react';
import { parseEther } from 'viem';
import { GMIContext } from '../../Context';
import { Collapse, Table } from 'react-bootstrap';
import { erc20Abi } from 'viem';
import { useAccount, useBlockNumber, useReadContract } from 'wagmi';
import { GM_INDEX_ADDRESS, tokens } from './constants';
import GMIndexABI from "../../abis/GMIndex.json"

import gmi_img3_url from '../../assets/img/gmi_img3.svg';
import gmi_img4_url from '../../assets/img/gmi_img4.svg';
import gmi_img5_url from '../../assets/img/gmi_img5.svg';
import gmi_img6_url from '../../assets/img/gmi_img6.svg';
import gmi_img7_url from '../../assets/img/gmi_img7.svg';
import gmi_img9_url from '../../assets/img/gmi_img9.svg';

import { truncateEther } from '../../utils/number';
import { useGMIPrice } from "../../hooks";


export default function ToGmi({ amount, setAmount, estimatedAmountOut }) {
    const queryClient = useQueryClient();
    const { depositToken } = useContext(GMIContext);
    const { data: blockNumber } = useBlockNumber({
        watch: true,
        cacheTime: 4_000,
    });

    const [openDropDown, setOpenDropDown] = useState(false);
    const [selectedCoin, setSelectedCoin] = useState("GMI");
    const [selectedCoinImg, setSelectedCoinImg] = useState(gmi_img9_url);
    const { address } = useAccount();

    const { data: gmiBalance, queryKey: gmiQueryKey } = useReadContract({
        abi: erc20Abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "balanceOf",
        args: [address],
    })
    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: gmiQueryKey });
    }, [blockNumber]);
    const gmiPrice = useGMIPrice()

    const amountHandler = (e) => {
        if (!e.target.value) {
            return setAmount("");
        }
        if (e.target.value.match("^[0-9]+(\.[0-9]*)?$")) {
            setAmount(e.target.value);
        }
    }
    const gmiDropDownHandler = () =>{
        if(openDropDown) {
            setOpenDropDown(false);
        }
    }
    window.addEventListener('click', (e) => {
        if(e.target !== document.querySelector('.to_gmi_dropdown_btn') && e.target !== document.querySelector('.to_gmi_dropdown_btn1')){
            gmiDropDownHandler();
        }
    })

    let amountOut;
    if (amount == '0') {
        if (isNaN(estimatedAmountOut) || estimatedAmountOut == '0') {
            amountOut = '';
        } else {
            amountOut = estimatedAmountOut;
        }
    } else {
        amountOut = amount;
    }

    let dollarValue;
    if (isNaN(estimatedAmountOut)) {
        dollarValue = (amount * gmiPrice).toFixed(2)
    } else if (estimatedAmountOut == 0) {
        dollarValue = '';
    } else {
        dollarValue = (estimatedAmountOut * gmiPrice).toFixed(2)
    }

    return (
        <div className="radius-8 bg-trans p-3 position-relative">
            <div className='d-flex v-center space-between'>
                <div>
                    <div className='font-20 bold-700 color-white'>
                        <input type="text" placeholder='0.00' className='buy_gmi_inputs' value={amountOut || ''} onChange={(e) => amountHandler(e)} />
                    </div>
                    <div className='font-14 bold-300 color-gray mt-2'>${
                        dollarValue
                    }</div>
                </div>
                <div className='text-end'>
                    <div className='d-flex v-center1 flex-end cursur-pointer'>
                        <img src={selectedCoinImg} width={22} className='mr-10 to_gmi_dropdown_btn' onClick={() => setOpenDropDown(!openDropDown)}/><div className='font-20 bold-500 color-white to_gmi_dropdown_btn1' onClick={() => setOpenDropDown(!openDropDown)}>{selectedCoin}</div>
                    </div>
                    <div className='font-14 bold-300 color-gray mt-2'>Balance: [{truncateEther(gmiBalance || 0n)}]</div>
                </div>
            </div>
        </div>
    )
}
