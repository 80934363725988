import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useBlockNumber, useReadContracts } from 'wagmi';
import { parseEther } from 'viem';
import { erc20Abi } from 'viem';
import { GM_INDEX_ADDRESS } from '../components/gmi/constants';
import GMIndexABI from '../abis/GMIndex.json';

const useIndexStats = (key) => {
    const queryClient = useQueryClient();

    const { data, queryKey } = useReadContracts({
        contracts: [
            {
                abi: GMIndexABI.abi,
                address: GM_INDEX_ADDRESS,
                chainId: 42161,
                functionName: "getPrice",
                args: [key, true],
            },
            {
                abi: GMIndexABI.abi,
                address: GM_INDEX_ADDRESS,
                chainId: 42161,
                functionName: "bankBalance",
                args: [key],
            },
            {
                abi: GMIndexABI.abi,
                address: GM_INDEX_ADDRESS,
                chainId: 42161,
                functionName: "targetBalance",
                args: [
                    key,
                    false,
                    "0",
                    false,
                ],
            },
            {
                abi: GMIndexABI.abi,
                address: GM_INDEX_ADDRESS,
                chainId: 42161,
                functionName: "totalControlledValue",
                args: [true],
            },
            {
                abi: GMIndexABI.abi,
                address: GM_INDEX_ADDRESS,
                chainId: 42161,
                functionName: "calculateFee",
                args: [
                    key,
                    parseEther("0.1").toString(),
                    false,
                ],
            },
            {
                abi: GMIndexABI.abi,
                address: GM_INDEX_ADDRESS,
                chainId: 42161,
                functionName: "calculateFee",
                args: [
                    key,
                    parseEther("0.1").toString(),
                    true,
                ],
            },
        ]
    })
    useEffect(() => {
        const intervalId = setInterval(() => {
            queryClient.invalidateQueries({ queryKey: queryKey });
        }, 20_000);

        return () => clearInterval(intervalId);
    }, []);

    // const { data: price } = useReadContract({
    //     abi: GMIndexABI.abi,
    //     address: GM_INDEX_ADDRESS,
    //     chainId: 42161,
    //     functionName: "getPrice",
    //     args: [key, true],
    // })
    // const { data: balance } = useReadContract({
    //     abi: GMIndexABI.abi,
    //     address: GM_INDEX_ADDRESS,
    //     chainId: 42161,
    //     functionName: "bankBalance",
    //     args: [key],
    // })
    // const { data: targetBalance } = useReadContract({
    //     abi: GMIndexABI.abi,
    //     address: GM_INDEX_ADDRESS,
    //     chainId: 42161,
    //     functionName: "targetBalance",
    //     args: [
    //         key,
    //         false,
    //         "0",
    //         false,
    //     ],
    // });
    // const { data: totalControlledValue } = useReadContract({
    //     abi: GMIndexABI.abi,
    //     address: GM_INDEX_ADDRESS,
    //     chainId: 42161,
    //     functionName: "totalControlledValue",
    //     args: [true],
    // });
    // const { data: depositFee } = useReadContract({
    //     abi: GMIndexABI.abi,
    //     address: GM_INDEX_ADDRESS,
    //     chainId: 42161,
    //     functionName: "calculateFee",
    //     args: [
    //         key,
    //         parseEther("0.1").toString(),
    //         false,
    //     ],
    // })
    // const { data: withdrawFee } = useReadContract({
    //     abi: GMIndexABI.abi,
    //     address: GM_INDEX_ADDRESS,
    //     chainId: 42161,
    //     functionName: "calculateFee",
    //     args: [
    //         key,
    //         parseEther("0.1").toString(),
    //         true,
    //     ],
    // })
    return {
        key,
        price: data?.[0].result || 0n,
        balance: data?.[1].result || 0n,
        targetBalance: data?.[2].result || 0n,
        totalControlledValue: data?.[3].result || 1n,
        depositFee: data?.[4].result || 0n,
        withdrawFee: data?.[5].result || 0n,
    }
}

export default useIndexStats;
