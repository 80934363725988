import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState, useContext } from 'react';
import { erc20Abi, maxUint256 } from 'viem'
import { useAccount, useBlockNumber, useReadContract, useSimulateContract, useWriteContract } from 'wagmi'
import { tokens, GM_INDEX_ADDRESS, ZAP_ADDRESS, GMX_EXCHANGE_ROUTER_ADDRESS, GMX_SYNTHETICS_ROUTER_ADDRESS } from './constants';
import { stringToHex, parseEther } from 'viem'

import { GMIContext } from '../../Context';
import GMIndexABI from "../../abis/GMIndex.json"

import Info from '../Info';
import FromGmi from './FromGmi';
import ToGmi from './ToGmi';
import gmi_img8_url from '../../assets/img/gmi_img8.svg';
import { Button } from 'react-bootstrap';
import { truncateEther } from "../../utils/number";
import useDoZap from "../../utils/doZap";
import { useExecutionFee, useGMIPrice, useIndexStats } from "../../hooks";
import useTestGMIZap from "../../utils/doThing";

export default function BuyGmi({ toggle, fromAmount, toAmount, setFromAmount, setToAmount }) {
    useTestGMIZap();
    const queryClient = useQueryClient();
    const { depositToken } = useContext(GMIContext);
    const { address } = useAccount();

    const { data: blockNumber } = useBlockNumber({
        watch: true,
        cacheTime: 4_000,
    });
    const ethStats = useIndexStats(tokens.ethGM.key);
    const btcStats = useIndexStats(tokens.btcGM.key);
    const solStats = useIndexStats(tokens.solGM.key);
    const stableStats = useIndexStats(tokens.stableGM.key);

    const lowestDepositFee = [ethStats, btcStats, solStats, stableStats].reduce(function(prev, curr) {
        return prev.depositFee < curr.depositFee ? prev : curr;
    });
    const lowestWithdrawFee = [ethStats, btcStats, solStats, stableStats].reduce(function(prev, curr) {
        return prev.withdrawFee < curr.withdrawFee ? prev : curr;
    });
    const lowestDepositFeeToken = tokens[Object.keys(tokens).filter(token => tokens[token].key == lowestDepositFee.key)[0]];
    const lowestWithdrawFeeToken = tokens[Object.keys(tokens).filter(token => tokens[token].key == lowestWithdrawFee.key)[0]];

    const { data: hash, writeContract } = useWriteContract() 
    const token = tokens[depositToken]
    const { data: tokenBalance, queryKey: balanceQueryKey } = useReadContract({
        abi: erc20Abi,
        address: token.address,
        chainId: 42161,
        functionName: "balanceOf",
        args: [address],
    })
    const { data: allowance, queryKey: allowanceKey } = useReadContract({
        abi: erc20Abi,
        address: token.address,
        chainId: 42161,
        functionName: "allowance",
        args: [
            address,
            token.name == "USDC" ? GMX_SYNTHETICS_ROUTER_ADDRESS : GM_INDEX_ADDRESS,
        ]
    })

    const { data: calculateFee, error: feeError } = useReadContract({
        abi: GMIndexABI.abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "calculateFee",
        args: [
            token.name == "USDC" ? lowestDepositFee.key : token.key,
            parseEther(fromAmount || "0.001"),
            false,
        ],
    })

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: allowanceKey });
        queryClient.invalidateQueries({ queryKey: balanceQueryKey });        
    }, [blockNumber]);
    
    const { data: approveData, error } = useSimulateContract({
        abi: erc20Abi,
        chainId: 42161,
        address: token.address,
        functionName: "approve",
        args: [
            token.name == "USDC" ? GMX_SYNTHETICS_ROUTER_ADDRESS : GM_INDEX_ADDRESS,
            maxUint256,
        ],
    });

    const name = stringToHex('WETH', {size: 32});
    const { data: depositData, error: depositError } = useSimulateContract({
        abi: GMIndexABI.abi,
        chainId: 42161,
        address: GM_INDEX_ADDRESS,
        functionName: "deposit",
        args: [
            token?.key,
            address,
            parseEther(fromAmount),
            "1",
        ],
        enabled: parseEther(fromAmount) > 0 && parseEther(fromAmount) <= tokenBalance,
    });
    const fee = (Number(calculateFee) / 100).toFixed(2).toString()
    const feeAmount = (Number(fee * fromAmount) / 100).toFixed(4)

    const { data: depositAmount } = useReadContract({
        abi: GMIndexABI.abi,
        chainId: 42161,
        address: GM_INDEX_ADDRESS,
        functionName: "deposit",
        args: [token?.key, address, parseEther(fromAmount || '0'), "1"],
        enabled: fromAmount && Number(fromAmount) > 0,
        account: address,
    });
    const desiredAmount = truncateEther(depositAmount, 4);

    async function doApprove() {
        writeContract(approveData?.request);
    }

    async function doDeposit() {
        if (token.name == "USDC") {
            zapper()
        } else {
            writeContract(depositData?.request, {
                onSuccess: () => {
                    setFromAmount('');
                    setToAmount('');
                }
            });    
        }
    }

    const executionFee = useExecutionFee();
    const zapper = useDoZap(lowestDepositFeeToken.name, BigInt(Math.floor(fromAmount * 1000000)), executionFee);
    const gmiPrice = useGMIPrice();
    const { data: fromPrice } = useReadContract({
        abi: GMIndexABI.abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "getPrice",
        args: [
            token.key,
            false,
        ],
    })

    const backup = fromAmount * (Number(fromPrice) / 1e18);
    const estimatedAmountOut = (backup / gmiPrice) * (100 - fee) / 100;

    return (
        <div>
            <div>
                <FromGmi
                    amount={fromAmount}
                    setAmount={setFromAmount}
                />
            </div>
            <div className='text-center btn-swap'>
                <img src={gmi_img8_url} width={48} className='cursur-pointer' onClick={toggle}/>
            </div>
            <div>
                <ToGmi
                    amount={token.name == "USDC" ? fromAmount / gmiPrice : desiredAmount}
                    setAmount={setToAmount}
                    estimatedAmountOut={estimatedAmountOut}
                />
            </div>
            <div className='d-flex space-between v-center py-4'>
                <div>
                    <span className='mr-10 font-16 bold-300 color-gray' >Fees</span>
                    <Info content="Swap Fees are reflected back into the GMI Vault"/>
                </div>
                <div className='font-16 bold-700 color-white'>
                    ${feeAmount} ({fee}%)
                </div>
            </div>
            <div>
                {allowance >= (token.name == "USDC" ? fromAmount * 1e6 : parseEther(fromAmount)) ? (
                    <Button
                        disabled={token.name == 'USDC' ? !zapper : !depositData}
                        onClick={() => doDeposit()}
                        className='gloop-btn-primary font-16 bold-700 radius-8 bg-green border-green color-dark p-10-25 my-2 w-100'
                    >
                        Buy GMI
                 </Button>
                ) : (
                    <Button
                        onClick={() => doApprove()}
                        className='gloop-btn-primary font-16 bold-700 radius-8 bg-green border-green color-dark p-10-25 my-2 w-100'
                    >
                        Approve
                 </Button>                
                )}
            </div>
        </div>  
    )
}
