import { Table } from 'react-bootstrap';

import { tokens } from './constants';
import gmi_img4_url from '../../assets/img/gmi_img4.svg';
import gmi_img5_url from '../../assets/img/gmi_img5.svg';
import gmi_img6_url from '../../assets/img/gmi_img6.svg';
import gmi_img7_url from '../../assets/img/gmi_img7.svg';
import { truncateEther } from '../../utils/number';
import { useIndexStats } from '../../hooks';

const TokenRow = ({ img, name, price, totalControlledValue, balance, depositFee, withdrawFee, ratio }) => {
    return (
        <tr className='cursur-pointer py-3'>
            <td>
                <img src={img} width={22} className='mr-10'/><span className='color-white font-16 bold-700 '>{name}</span>
            </td>
            <td className='color-white font-16 bold-400'>${(Number(price) / 1e18).toFixed(2)}</td>
            <td className='color-white font-16 bold-400'>{(Number(balance) / 1e18).toFixed(4)}</td>
            <td className='color-white font-16 bold-400'>{truncateEther((price * balance) * 100n / totalControlledValue, 2)}%</td>
            <td className='color-white font-16 bold-400'>{ratio}%</td>
            <td className='color-white font-16 bold-400'>{Number(depositFee) / 100}%</td>
            <td className='color-white font-16 bold-400'>{Number(withdrawFee) / 100}%</td>
        </tr>
    );
}

const MobileTokenRow = ({token, stats}) => {
    return (
        <div className='d-flex space-between'>
            <div>
                <div className='color-gray font-14 bold-300  py-2'>Asset</div>
                <div className='color-gray font-14 bold-300  py-2'>Price</div>
                <div className='color-gray font-14 bold-300  py-2'>Available</div>
                <div className='color-gray font-14 bold-300  py-2'>Composition</div>
                <div className='color-gray font-14 bold-300  py-2'>Target</div>
                <div className='color-gray font-14 bold-300  py-2'>Deposit Fee</div>
                <div className='color-gray font-14 bold-300  py-2'>Withdrawal Fee</div>
            </div>
            <MobileTokenRowStats
                {...token}
                {...stats}
            />
        </div>
    )
}
const MobileTokenRowStats = ({ img, name, price, totalControlledValue, balance, depositFee, withdrawFee, ratio }) => {
    return (
        <div className='text-end'>
            <div className='py-2'>
            <img src={img} width={22} className='mr-10'/><span className='color-white font-14 bold-700 '>{name}</span></div>
            <div className='color-white font-14 bold-400 py-2'>${(Number(price) / 1e18).toFixed(2)}</div>
            <div className='color-white font-14 bold-400 py-2'>{(Number(balance) / 1e18).toFixed(4)}</div>
            <div className='color-white font-14 bold-400 py-2'>{(truncateEther((price * balance) / totalControlledValue, 4) * 100).toFixed(2)}%</div>
            <div className='color-white font-14 bold-400 py-2'>{ratio}%</div>
            <div className='color-white font-14 bold-400 py-2'>{Number(depositFee) / 100}%</div>
            <div className='color-white font-14 bold-400 py-2'>{Number(withdrawFee) / 100}%</div>
        </div>
    );
}

export default function GmiBasket() {
    const ethStats = useIndexStats(tokens.ethGM.key);
    const btcStats = useIndexStats(tokens.btcGM.key);
    const solStats = useIndexStats(tokens.solGM.key);
    const stableStats = useIndexStats(tokens.stableGM.key);

    return (
        <div className="radius-8 bg-trans p-4 ">
            <div className='font-18 bold-700 color-white'>GMI Basket</div>
            <div>
                <hr className='hr-3 border-dark-green'/>
            </div>
            <div className='desktop-show'>
                <Table borderless className='gmi_basket_table'>
                    <thead >
                        <tr>
                            <th className='color-gray font-16 bold-300  py-3'>Asset</th>
                            <th className='color-gray font-16 bold-300  py-3'>Price</th>
                            <th className='color-gray font-16 bold-300  py-3'>Available</th>
                            <th className='color-gray font-16 bold-300  py-3'>Current</th>
                            <th className='color-gray font-16 bold-300  py-3'>Target</th>
                            <th className='color-gray font-16 bold-300  py-3'>Deposit Fee</th>
                            <th className='color-gray font-16 bold-300  py-3'>Withdrawal Fee</th>
                        </tr>
                    </thead>
                    <tbody>

                        <TokenRow
                            {...tokens.ethGM}
                            {...ethStats}
                        />
                        <TokenRow
                            {...tokens.btcGM}
                            {...btcStats}
                        />
                        <TokenRow
                            {...tokens.solGM}
                            {...solStats}
                        />
                        <TokenRow
                            {...tokens.stableGM}
                            {...stableStats}
                        />

                    </tbody>
                </Table>
            </div>
            <div className='mobile-show'>
                <MobileTokenRow
                    token={tokens.ethGM}
                    stats={ethStats}
                />
                <div>
                    <hr className='hr-1 border-dark-green'/>
                </div>
                <MobileTokenRow
                    token={tokens.btcGM}
                    stats={btcStats}
                />
                <div>
                    <hr className='hr-1 border-dark-green'/>
                </div>
                <MobileTokenRow
                    token={tokens.solGM}
                    stats={solStats}
                />
                <div>
                    <hr className='hr-1 border-dark-green'/>
                </div>
                <MobileTokenRow
                    token={tokens.stableGM}
                    stats={stableStats}
                />
            </div>
        </div>
    )
}
