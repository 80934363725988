import { useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { erc20Abi, parseEther } from 'viem';
import { useAccount, useBlockNumber, useReadContract, useSimulateContract, useWriteContract } from 'wagmi';

import Info from '../Info';
import FromGmi from './FromGmi';
import ToGmi from './ToGmi';
import gmi_img8_url from '../../assets/img/gmi_img8.svg';
import { GMIContext } from '../../Context';
import { GM_INDEX_ADDRESS, tokens } from './constants';
import GMIndexABI from "../../abis/GMIndex.json"
import { truncateEther } from "../../utils/number";
import { useGMIPrice } from "../../hooks";

export default function SellGmi({ toggle }) {
    const queryClient = useQueryClient();
    const { data: blockNumber } = useBlockNumber({
        watch: true,
        cacheTime: 4_000,
    });
    
    const [fromAmount, setFromAmount] = useState('');
    const [toAmount, setToAmount] = useState('');

    const { address } = useAccount();
    const { depositToken } = useContext(GMIContext);
    
    const { data: tokenBalance, queryKey: gmiQueryKey } = useReadContract({
        abi: erc20Abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "balanceOf",
        args: [address],
    })

    const token = tokens[depositToken];
    const { writeContract } = useWriteContract();
    const { data: withdrawData, error } = useSimulateContract({
        abi: GMIndexABI.abi,
        chainId: 42161,
        address: GM_INDEX_ADDRESS,
        functionName: "withdraw",
        args: [token.key, parseEther(fromAmount), address, "1"],
        enabled: parseEther(fromAmount) > 0 & parseEther(fromAmount) <= tokenBalance,
    });

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: gmiQueryKey });
    }, [blockNumber]);

    async function doWithdraw() {
        writeContract(withdrawData?.request, {
            onSuccess: () => {
                setFromAmount('');
                setToAmount('');
            }
        });
    }

    const { data: calculateFee, error: feeError } = useReadContract({
        abi: GMIndexABI.abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "calculateFee",
        args: [
            token.key,
            parseEther(fromAmount),
            true,
        ],
    })
    const fee = (Number(calculateFee) / 100).toFixed(2).toString()
    const feeAmount = (Number(fee * fromAmount) / 100).toFixed(4)
    const price = useGMIPrice();

    const amountOut = token.name == "USDC" ? fromAmount * price * .99 : truncateEther(withdrawData?.result);

    return (
        <div>
            <div>
                <ToGmi
                    amount={fromAmount}
                    setAmount={setFromAmount}
                />
            </div>
            <div className='text-center btn-swap'>
                <img src={gmi_img8_url} width={48} className='cursur-pointer' onClick={toggle}/>
            </div>
            <div>
                <FromGmi
                    amount={amountOut == '0' ? '' : amountOut}
                    setAmount={setToAmount}
                    showUSDC={false}
                />
            </div>
            <div className='d-flex space-between v-center py-4'>
                <div>
                    <span className='mr-10 font-16 bold-300 color-gray'>Fees</span>
                    <Info content="Fees are based on pool target ratios"/>
                </div>
                <div className='font-16 bold-700 color-white'>
                    ${feeAmount} ({fee}%)
                </div>
            </div>
            <div>
                <Button
                    disabled={!withdrawData}
                    onClick={() => doWithdraw()}
                    className='gloop-btn-primary font-16 bold-700 radius-8 bg-green border-green color-dark p-10-25 my-2 w-100'
                >
                    Sell GMI
                </Button>
            </div>
        </div>  
    )
}
