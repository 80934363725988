import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAccount, useReadContract } from 'wagmi';
import { erc20Abi } from 'viem';
import { GM_INDEX_ADDRESS } from './constants';
import GMIndexABI from "../../abis/GMIndex.json"
import { truncateEther } from '../../utils/number';
import { useGMIPrice } from "../../hooks"
import { loadAPR } from './query';

export default function LeftPart() {
    // const [apr, setApr] = useState(localStorage.getItem("APR") || "...");
    const { data: apr } = useQuery({
        queryKey: ['apr'],
        queryFn: loadAPR,
        refetchInterval: 10_000,
        cacheTime: 10_000,
        initialData: Number(localStorage.getItem("apr")) || "...",
    });

    if (apr != "...") {
        localStorage.setItem("apr", apr);
    }
    
    const { address } = useAccount();
    const { data: totalSupply } = useReadContract({
        abi: erc20Abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "totalSupply",
        args: [],
    })
    const { data: balance } = useReadContract({
        abi: erc20Abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "balanceOf",
        args: [address],
    })
    const { data: totalControlledValue } = useReadContract({
        abi: GMIndexABI.abi,
        address: GM_INDEX_ADDRESS,
        chainId: 42161,
        functionName: "totalControlledValue",
        args: [true],
    })
    const price = useGMIPrice();

    return (
        <div className="radius-8 bg-trans p-4 min-h-455">
            <div className='d-flex v-center space-between'>
                <div className='font-18 bold-600 color-white'>GMI</div>
                <div
                    className='font-16 bold-700 color-white bg-trans radius-8 py-2 px-3'
                    style={{
                        borderStyle: 'solid',
                        borderColor: '#19FB80',
                        borderWidth: '1.5px',
                    }}
                >
                    {apr == "..." ? "..." : Number(apr).toFixed(4)}% APY
                </div>
            </div>
            <div>
                <hr className='hr-3 border-dark-green'/>
            </div>
            <div className='d-flex v-center space-between'>
                <div style={{ textAlign: 'left'}}>
                    <div className='font-16 bold-300 color-gray my-3'>
                        <span className='mr-10' >GMI Price</span>
                        {/* <Info content="Lorem ipsum dolor sit amet consectetur lorem111"/> */}
                    </div>
                    <div className='font-16 bold-300 color-gray my-3'>
                        <span className='mr-10' >TVL</span>
                        {/* <Info content="Lorem ipsum dolor sit amet consectetur lorem222"/> */}
                    </div>
                    <div className='font-16 bold-300 color-gray my-3'>
                        <span className='mr-10' >Total Supply</span>
                        {/* <Info content="Lorem ipsum dolor sit amet consectetur lorem3333"/> */}
                    </div>
                </div>
                <div style={{ textAlign: 'right'}}>
                    <div className='font-16 bold-700 color-white my-3'>
                        ${price}
                    </div>
                    <div className='font-16 bold-700 color-white my-3'>
                        ${truncateEther(totalControlledValue, 4)}
                    </div>
                    <div className='font-16 bold-700 color-white my-3'>
                        {truncateEther(totalSupply)} GMI
                    </div>
                </div>
            </div>
            <div>
                <hr className='hr-1 border-dark-green'/>
            </div>
            <div className='d-flex v-center space-between'>
                <div>
                    <div className='font-16 bold-300 color-gray my-3 d-flex'>
                        <span className='mr-10' >Balance</span>
                        {/* <Info content="Lorem ipsum dolor sit amet consectetur lorem55"/> */}
                    </div>
                    <div className='font-16 bold-300 color-gray my-3'>
                        <span className='mr-10' >Total Value</span>
                        {/* <Info content="Lorem ipsum dolor sit amet consectetur lorem66"/> */}
                    </div>
                </div>
                <div style={{ textAlign: 'right' }}>
                    <div className='font-16 bold-700 color-white my-3'>
                        {truncateEther(balance) } GMI
                    </div>
                    <div className='font-16 bold-700 color-white my-3'>
                        ${(price * truncateEther(balance)).toFixed(2)}
                    </div>
                </div>
            </div>
        </div>
    )
}
