import { MARKETS } from './constants';

const buildQuery = (startTime) => `{
    _0x47c031236e19d024b42f8AE6780E44A573170703_lte_start_of_period: collectedMarketFeesInfos(
        orderBy:timestampGroup
        orderDirection:desc
        where: {
          marketAddress: "0x47c031236e19d024b42f8ae6780e44a573170703"
          period: "1h"
          timestampGroup_lte: ${startTime}
        },
        first: 1
    ) {
        cumulativeFeeUsdPerPoolValue
    }

    _0x47c031236e19d024b42f8AE6780E44A573170703_recent: collectedMarketFeesInfos(
        orderBy: timestampGroup
        orderDirection: desc
        where: {
          marketAddress: "0x47c031236e19d024b42f8ae6780e44a573170703"
          period: "1h"
        },
        first: 1
    ) {
        cumulativeFeeUsdPerPoolValue
    }
  
    _0x70d95587d40A2caf56bd97485aB3Eec10Bee6336_lte_start_of_period: collectedMarketFeesInfos(
      orderBy:timestampGroup
      orderDirection:desc
      where: {
        marketAddress: "0x70d95587d40a2caf56bd97485ab3eec10bee6336"
        period: "1h"
        timestampGroup_lte: ${startTime}
      },
      first: 1
    ) {
      cumulativeFeeUsdPerPoolValue
    }
    _0x70d95587d40A2caf56bd97485aB3Eec10Bee6336_recent: collectedMarketFeesInfos(
        orderBy: timestampGroup
        orderDirection: desc
        where: {
          marketAddress: "0x70d95587d40a2caf56bd97485ab3eec10bee6336"
          period: "1h"
        },
        first: 1
    ) {
        cumulativeFeeUsdPerPoolValue
    }
  
    _0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9_lte_start_of_period: collectedMarketFeesInfos(
      orderBy:timestampGroup
      orderDirection:desc
      where: {
        marketAddress: "0x09400d9db990d5ed3f35d7be61dfaeb900af03c9"
        period: "1h"
        timestampGroup_lte: ${startTime}
      },
      first: 1
    ) {
      cumulativeFeeUsdPerPoolValue
    }
    _0x09400D9DB990D5ed3f35D7be61DfAEB900Af03C9_recent: collectedMarketFeesInfos(
        orderBy: timestampGroup
        orderDirection: desc
        where: {
          marketAddress: "0x09400d9db990d5ed3f35d7be61dfaeb900af03c9"
          period: "1h"
        },
        first: 1
    ) {
        cumulativeFeeUsdPerPoolValue
    }
  
    _0xB686BcB112660343E6d15BDb65297e110C8311c4_lte_start_of_period: collectedMarketFeesInfos(
      orderBy:timestampGroup
      orderDirection:desc
      where: {
        marketAddress: "0xb686bcb112660343e6d15bdb65297e110c8311c4"
        period: "1h"
        timestampGroup_lte: ${startTime}
      },
      first: 1
    ) {
      cumulativeFeeUsdPerPoolValue
    }
    _0xB686BcB112660343E6d15BDb65297e110C8311c4_recent: collectedMarketFeesInfos(
        orderBy: timestampGroup
        orderDirection: desc
        where: {
          marketAddress: "0xb686bcb112660343e6d15bdb65297e110c8311c4"
          period: "1h"
        },
        first: 1
    ) {
        cumulativeFeeUsdPerPoolValue
    }
}
`
const URL = "https://subgraph.satsuma-prod.com/3b2ced13c8d9/gmx/synthetics-arbitrum-stats/api"

async function loadAPR() {
    const startTime = Math.floor((new Date() - 7 * 3600 * 1000 * 24) / 1000)
    let response;
    try {
        response = await fetch(URL, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                "query": buildQuery(startTime),
            })
        })
    } catch(err) {
        return "..."
    }

    const jsonData = await response.json()
    const data = jsonData?.data;
    const aprs = {}
    let total = 0;
    for (let market of Object.values(MARKETS)) {
        const x1 = data[`_${market.market}_lte_start_of_period`][0]['cumulativeFeeUsdPerPoolValue'];
        const x2 = data[`_${market.market}_recent`][0]['cumulativeFeeUsdPerPoolValue'];
        const income = x2 - x1
        const year = Math.floor(365 / 7);
        const apr = income * year / 10 ** 26;
        aprs[market.market] = apr
        total += apr * market.ratio / 100
    }
    total *= 1.05; // add arb incentives
    return total / 100;
}

export {
    loadAPR,
}
